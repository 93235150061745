




















import { Product } from '@/common/product'
import { Component, Prop, Vue } from 'vue-property-decorator'
import Mixins from '@/common/mixin'

@Component({
  mixins: [Mixins]
})
export default class ProductAndAmount extends Vue {
  @Prop() private readonly product!: Product;
  @Prop() private readonly unit!: string;

  get amount (): number {
    let amount = this.product.amount
    if (this.unit === 'min') {
      amount *= 60
    }
    return Math.round((amount + Number.EPSILON) * 100) / 100
  }
}
