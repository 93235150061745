







import { Component, Vue } from 'vue-property-decorator'
import Navbar from '@/components/Navbar.vue'
import { tr } from '@/common/dataloader'

@Component({
  components: {
    Navbar
  }
})
export default class App extends Vue {
  private readonly tr = tr;
}
