





























import { Component, Prop, Vue } from 'vue-property-decorator'
import { DataLoader, tr } from '../common/dataloader'
import Mixins from '@/common/mixin'

@Component({
  mixins: [Mixins]
})
export default class Navbar extends Vue {
  @Prop() private readonly title!: string;
  private currentLocale: string;
  private readonly tr = tr;

  constructor () {
    super()
    this.currentLocale = Vue.$cookies.get('locale')
    this.currentLocale = this.currentLocale ? this.currentLocale : 'ZHCN'
    DataLoader.getInstance().locale = this.currentLocale
  }

  private static readonly LocaleNames: Record<string, string> = {
    ZHCN: '中文', ENUS: 'English', FRFR: 'Français'
  };

  onSelectLocale (locale: string) {
    DataLoader.getInstance().locale = locale
    Vue.$cookies.set('locale', locale)
  }

  get localeName (): string {
    return this.getLocaleName(this.currentLocale)
  }

  getLocaleName (locale: string): string {
    return Navbar.LocaleNames[locale]
  }
}
