

























import { Options } from '@/common/options'
import { Component, VModel, Vue, Watch } from 'vue-property-decorator'
import { tr } from '@/common/dataloader'
import Mixins from '@/common/mixin'

@Component({
  mixins: [Mixins]
})
export default class OptionsPanel extends Vue {
  @VModel() options!: Options;
  private tr = tr;

  trs (arr: string[]) {
    return arr.map(s => { return { value: s, text: tr(s) } })
  }
}
