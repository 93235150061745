




































































import { Component, Prop, Watch, VModel, Vue, Emit } from 'vue-property-decorator'
import Mixins from '../common/mixin'
import VuePopper from '@livelybone/vue-popper'
import { DataLoader, tr } from '../common/dataloader'
import { Item, UserInputProduct } from '../common/product'
import '@livelybone/vue-popper/lib/css/index.css'

@Component({
  mixins: [Mixins],
  components: {
    VuePopper
  }
})
export default class ProductPicker extends Vue {
  @Prop() private defaultMessage!: string;
  @Prop() private showPicker = false;
  @Prop() private unit!: string;
  @Prop() private defaultProduct!: UserInputProduct;
  @VModel() private selectedProduct!: UserInputProduct;

  private readonly tr = tr;
  private readonly dataLoader = DataLoader.getInstance();

  private selectedItem: Item = Item.Empty;
  private amount = 1;

  private panel: Item[][];
  private static PanelCached: Item[][];

  constructor () {
    super()
    this.panel = ProductPicker.GetPanel()

    this.selectedItem = this.defaultProduct.item
    let amount = this.defaultProduct.amount
    if (this.unit !== this.defaultProduct.unit) {
      if (this.unit === 's') {
        amount /= 60
      } else {
        amount *= 60
      }
    }
    this.amount = amount
  }

  private static CreatePanel (): Item[][] {
    const panel: Item[][] = []
    for (let i = 11; i <= 17; i++) {
      const panelRow: Item[] = []
      for (let j = 1; j <= 12; j++) {
        const gridIndex = i * 100 + j
        const item = DataLoader.getInstance().AllItems.find((item) => {
          return item.GridIndex === gridIndex
        })
        if (item !== undefined) {
          panelRow.push(item)
        } else {
          panelRow.push(Item.Empty)
        }
      }
      panel.push(panelRow)
    }
    for (let i = 21; i <= 24; i++) {
      const panelRow: Item[] = []
      for (let j = 1; j <= 12; j++) {
        const gridIndex = i * 100 + j
        const item = DataLoader.getInstance().AllItems.find((item) => {
          return item.GridIndex === gridIndex
        })
        if (item !== undefined) {
          panelRow.push(item)
        } else {
          panelRow.push(Item.Empty)
        }
      }
      panel.push(panelRow)
    }

    return panel
  }

  private static GetPanel (): Item[][] {
    if (!this.PanelCached) {
      this.PanelCached = this.CreatePanel()
    }
    return this.PanelCached
  }

  @Emit('click')
  onClick (item = Item.Empty) {
    if (item.ID) {
      this.selectedItem = item
    }
  }

  @Watch('unit')
  onUnitChange () {
    if (this.unit === 'min') {
      this.amount *= 60
    } else {
      this.amount /= 60
    }
  }

  @Watch('selectedItem')
  @Watch('amount')
  onChanged () {
    this.selectedProduct = new UserInputProduct(
      this.selectedItem, +this.amount, this.unit
    )
  }

  @Watch('defaultProduct', { deep: true, immediate: true })
  onDefaultProductChanged () {
    if (this.selectedItem.ID !== this.defaultProduct.item.ID) {
      this.selectedItem = DataLoader.getInstance().ItemMap[this.defaultProduct.item.ID]
    }
    if (this.amount !== this.defaultProduct.amount || this.unit !== this.defaultProduct.unit) {
      let amount = this.defaultProduct.amount
      if (this.unit !== this.defaultProduct.unit) {
        if (this.unit === 's') {
          amount /= 60
        } else {
          amount *= 60
        }
      }
      this.amount = amount
    }
  }
}
