




























import { Component, Vue, Prop } from 'vue-property-decorator'
import { BvEvent } from 'bootstrap-vue'
import { tr } from '@/common/dataloader'
import { Product } from '@/common/product'
import { Planner } from '@/common/planner'
import { Options } from '@/common/options'
import DetailPanel from '@/components/DetailPanel.vue'
import GraphPanel from '@/components/GraphPanel.vue'

@Component({
  components: {
    DetailPanel,
    GraphPanel
  }
})
export default class PipelinePanel extends Vue {
  @Prop() planner?: Planner;
  @Prop() options?: Options;
  @Prop() targets?: Product[];
  @Prop() activePanel?: string;
  private readonly tr = tr;

  private onActivateTab (newTabIndex: number, prevTabIndex: number, e: BvEvent) {
    const query = this.$router.currentRoute.query
    const newActivePanel = newTabIndex === 0 ? 'graph' : newTabIndex === 1 ? 'details' : 'summary'
    this.$router.replace({ path: newActivePanel, query: query })
  }
}
